<template>
  <div class="containers">
    <navbar></navbar>
    <div class="contents py-20 px-15">
      <p class="bold fs-xxl t-center m-b-5">
        {{ lang == "zh" ? detail.title : detail.title_en }}
      </p>
      <p class="t-color2 t-center">{{ detail.updated_at }}</p>
      <p class="fs-md t-color2 m-t-25">
        <span class="t-color4">{{
          $route.query.type == 1 ? $t("news.latest") : $t("news.facts")
        }}</span
        ><span> > {{ lang == "zh" ? detail.title : detail.title_en }}</span>
      </p>
      <div
        class="m-t-15 m-b-10 lh-2 word"
        style="letter-spacing: 0.5px"
        v-html="lang == 'zh' ? detail.content : detail.content_en"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: {},
    };
  },
  computed: {
    lang() {
      return this.$storage.get("lang");
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$axios
        .get(this.$api.newsdetail + "/" + this.$route.query.id, {})
        .then((res) => {
          this.detail = res.data[0];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .word {
  img {
    width: 100%;
    margin: 10px 0;
  }
}
</style>